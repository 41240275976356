import dan_headshot from '../assets/png/headshot-dan.png';
import doug_headshot from '../assets/png/headshot-doug.png';
import erin_headshot from '../assets/png/headshot-erin.png';
import karen_headshot from '../assets/png/headshot-karen.png';
import neil_headshot from '../assets/png/headshot-neil.png';
import wes_headshot from '../assets/png/headshot-wes.png';
import OCI from '../assets/png/logo-OCI.png';
import AFRICA from '../assets/png/logo-africanazarene.png';
import CENGN from '../assets/png/logo-cengn.png';
import CHATHAM from '../assets/png/logo-chathamkenthealth.png';
import DIGITALSUPER from '../assets/png/logo-digitalsupercluster.png';
import GREYBRUCE from '../assets/png/logo-greybrucehealth.png';
import NRC_IRAP from '../assets/png/logo-irap.png';
import KENYATTA from '../assets/png/logo-jomokenyatta.png';
import KINGSTON_HEALTH from '../assets/png/logo-kingstonhealth.png';
import LAURIER from '../assets/png/logo-laurier.png';
import NGEN from '../assets/png/logo-ngen.png';
import NORTHSTARAIR from '../assets/png/logo-northstarair.png';
import NRC_CNRC from '../assets/png/logo-nrc.png';
import SAINT_MARYS_HOSPITAL from '../assets/png/logo-saint-marys-general-hosital.png';
import GRAND_RIVER_HOSPITAL from '../assets/png/logo-grand-river-hospital.png';
import QUEENS from '../assets/png/logo-queens.png';
import QUINTE_HEALTH from '../assets/png/logo-quintehealth.png';
import SASKATCHEWAN from '../assets/png/logo-saskatchewanhealth.png';
import THUNDERBAY from '../assets/png/logo-thunderbayhealth.png';
import UFMG from '../assets/png/logo-ufmg.png';
import YORK from '../assets/png/logo-york.png';

import ngen_manufacturing from '../assets/png/Ngen-white.png';
export const ABOUT = {
  metadata: {
    description:
      'Distributive created the Distributive Compute Protocol (DCP). A standard for matching supply and demand for compute to enable anyone, academic or commercial, with a need for computing power to have access to that power within any web connected device on the platform.',
    keywords: '',
  },
  hero: {
    title: 'Company Overview',
    description:
      'Computing power has become the currency of knowledge creation. Our mission is to provide a platform to allow everyone access to computing resources and use distributed computing to solve tomorrow’s greatest challenges.',
    bgImage:
      'bg-[url(./assets/png/background-with-logo.png)] bg-cover bg-no-repeat !bg-center ',
  },
  news: {
    title: 'Recent News',
    description:
      'There is always a lot happening at Distributive. Here are some noteworthy announcements. If you have any questions or would like to know please reach out to info@distributive.network',
    button: {
      text: 'VIEW ALL RECENT NEWS',
      textColour: 'text-white',
      url: '/news',
    },
    numOfMentions: 4,
  },
  media: {
    title: 'Media Mentions',
    description:
      'If you don’t want to take our word for it, here are some stories from other sources that include distributive and our impact on the world around us.',
    button: {
      text: 'VIEW ALL MEDIA MENTIONS',
      textColour: 'text-white',
      url: '/media',
    },
    numOfMentions: 4,
  },
  leadership: {
    title: 'Leadership',
    subTitle:
      "Distributive's leadership team comprises experienced and dedicated professionals who embody the company's values of courage, ambition, and service. With a strong background in leadership, high performance computing, and enterprise software development, we bring a wealth of knowledge and expertise to the table. Above all, our commitment to service, experiential learning, and putting others first, sets us apart as leaders.",
    bgImage: 'bg-[url(./assets/jpg/green-leftside.jpg)]',
    numOfCols: 'lg:grid-cols-3',
    leaders: [
      {
        src: dan_headshot,
        name: 'Dan Desjardins',
        title: 'CEO',
        description:
          'Computational Physicist and former RCAF Officer, 10 years of experience in high performance computing between RMCC, Queen’s U, and Distributive.',
        url: 'https://www.linkedin.com/in/dan-desjardins/',
        displayUrl: 'linkedin.com/in/dan-desjardins',
      },
      {
        src: wes_headshot,
        name: 'Wes Garland',
        title: 'CTO',
        description:
          '30 years of experience in commercial software development and telecommunications systems. Expert in server-side JavaScript platform development.',
        url: 'https://www.linkedin.com/in/wesley-garland-2203a23',
        displayUrl: 'linkedin.com/in/wesley-garland',
      },
      {
        src: karen_headshot,
        name: 'Karen Batch',
        title: 'Dir. AI Task',
        description:
          'M.Sc. of Computing in AI, an expert in ML for use in Health Care. Published research using Natural Language Processing to detect cancer from radiology reports to identify trends of metastatic spread.',
        url: 'https://www.linkedin.com/in/karen-batch-013348111',
        displayUrl: 'linkedin.com/in/karen-batch',
      },
      {
        src: doug_headshot,
        name: 'Doug Stewart',
        title: 'Co-founder / Director of Special Projects',
        description:
          'Doug is a proven leader in business, jazz bands, and sailboats. As a long-time entrepreneur, he makes the world a happier place by forging lasting friendships and bringing smart people together.',
        url: 'https://www.linkedin.com/in/douglas-stewart-686b47135/',
        displayUrl: 'linkedin.com/in/douglas-stewart',
      },
      {
        src: neil_headshot,
        name: 'Neil Laing',
        title: 'VP Design',
        description:
          'Award winning designer leading marketing, branding, UX/UI and product design. Founding employee of AI start-up Rubikloud (acquired 2020), contributor to products such as Tableau and Uptime.',
        url: 'https://www.linkedin.com/in/neil-laing/',
        displayUrl: 'linkedin.com/in/neil-laing',
      },
      {
        src: erin_headshot,
        name: 'Erin Peterson',
        title: 'Artificial Intelligence Software Developer',
        description:
          'Erin is a Computer Engineer specializing in Artificial Intelligence who focuses on innovations that prioritize ethical considerations and contribute to societal well-being.',
        url: 'https://www.linkedin.com/in/erinlynnpeterson',
        displayUrl: 'linkedin.com/in/erinlynnpeterson',
      },
    ],
  },
  academicPartners: {
    title: 'Our Academic Partners',
    subHeading: '',
    partnerList: [
      {
        src: UFMG,
      },
      {
        src: QUEENS,
      },
      {
        src: LAURIER,
      },
      {
        src: KENYATTA,
      },
      {
        src: AFRICA,
      },
      {
        src: YORK,
      },
    ],
  },
  healthcarePartners: {
    title: 'Our Healthcare Partners',
    subHeading: '',
    partnerList: [
      {
        src: QUINTE_HEALTH,
      },
      {
        src: GREYBRUCE,
      },
      {
        src: CHATHAM,
      },
      {
        src: KINGSTON_HEALTH,
      },
      {
        src: SAINT_MARYS_HOSPITAL,
      },
      {
        src: GRAND_RIVER_HOSPITAL,
      },
      {
        src: THUNDERBAY,
      },
      {
        src: SASKATCHEWAN,
      },
    ],
  },
  operationPartners: {
    title: 'Our Airport and Aircraft Operations Partners',
    subHeading: '',
    partnerList: [
      {
        src: NORTHSTARAIR,
      },
    ],
  },
  organizationPartners: {
    title:
      'Our Supercluster, Government and Para-governmental Organization Partners',
    subHeading: '',
    partnerList: [
      {
        src: NRC_CNRC,
      },
      {
        src: NRC_IRAP,
      },
      {
        src: NGEN,
      },
      {
        src: DIGITALSUPER,
      },
      {
        src: CENGN,
      },
      {
        src: OCI,
      },
    ],
  },
  investors: {
    title: 'Our Leading Investors',
    subHeading: '<Placeholder text here>',
    bgImage: 'bg-[url(./assets/jpg/screen-chart.jpg)]',
    investorInfo: [
      {
        title: 'COMPANY NAME',
        description:
          'Placeholder text here. Your own historical data is used to train systems to build custom schedules with easy constraint/requirement integration.',
        src: ngen_manufacturing,
      },
      {
        title: 'COMPANY NAME',
        description:
          'Placeholder text here. Your own historical data is used to train systems to build custom schedules with easy constraint/requirement integration.',
        src: ngen_manufacturing,
      },
      {
        title: 'COMPANY NAME',
        description:
          'Placeholder text here. Your own historical data is used to train systems to build custom schedules with easy constraint/requirement integration.',
        src: ngen_manufacturing,
      },
    ],
  },
  cta: {
    title: 'Interested in working at Distributive?',
    subHeading:
      'Reach out to learn more, or visit our careers page to explore joining our growing team.',
    ctaText: 'Careers at Distributive',
    ctaBgColour: 'bg-[#17484D]',
    ctaTextColour: 'text-[white]',
    bgImage: 'bg-[url(./assets/jpg/lecture-room.jpg)]',
    href: '/careers',
  },
};
